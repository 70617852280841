import { useState } from "react";
import { useUserL3Entities } from "../profile/hooks/use-user-l3-entities";
import { Modal as CreateProductModal } from "../profile/products/create/modal";
import { Button } from "../../components/button";
import { Add16 } from "@bphxd/ds-core-react/lib/icons/index";
import { Link } from "../../components/Link";

export function CreateProduct({ isLink, isButtonPrimary }: { isLink?: boolean; isButtonPrimary?: boolean }) {
  const [isCreateProductModalOpen, setIsCreateProductModalOpen] = useState(false);
  const { userL3Entities } = useUserL3Entities();
  const isOwnerOrDelegate = !!userL3Entities?.length;

  return (
    <>
      {isOwnerOrDelegate &&
        (isLink ? (
          <Link
            href={{} as string}
            onClick={() => {
              setIsCreateProductModalOpen(true);
            }}
            name="create-new-product-link"
            data-testid={"create-new-product-link"}
          >
            create a new product.
          </Link>
        ) : isButtonPrimary ? (
          <Button
            data-testid="create-a-product-cta"
            className="bg-primary"
            name="create-a-product-from-my-products-page-side-cta"
            onClick={() => setIsCreateProductModalOpen(true)}
            level="tertiary"
            rounded="pill"
            size="sm"
            theme="standard"
          >
            Create a product
          </Button>
        ) : (
          <Button
            name="create-a-product"
            level="primary"
            theme="darker"
            rounded="pill"
            Icon={Add16}
            onClick={() => {
              setIsCreateProductModalOpen(true);
            }}
          >
            Create a product
          </Button>
        ))}

      <CreateProductModal
        isModalOpen={isCreateProductModalOpen}
        toggle={() => {
          setIsCreateProductModalOpen((o) => !o);
        }}
        yallaProductIsEditable={false}
      />
    </>
  );
}
