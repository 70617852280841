import { Col, Row } from "reactstrap";
import { PageSection, PageWithNoPadding } from "../../components/page-template";
import { EventCards } from "../../components/cards/event-cards";

import { EventDetails, EVENT_INFO } from "./data/event-info";
import { AskAxel } from "./ask-axel";
import { UserSurveyBanner } from "./user-survey-banner";
import { PromoCards } from "./promo-cards";
import { ProductCards } from "./product-cards";
import { CreateProduct } from "./create-product";
import { JoinProductButton } from "./join-product-button";
import { useProductContext } from "../../providers/product-provider";
import { Loader } from "../../components/spinners/loading-spinner";
import { YallaCard } from "./yalla-card";
import { useFeatureHub } from "featurehub-react-sdk";
import { useEffect } from "react";

export function WelcomePage({ events }: { events?: EventDetails[] }) {
  const { myProductsList: myProducts, loading } = useProductContext();
  const { client } = useFeatureHub();
  useEffect(() => {
    if (client.repository().readyness === "Ready") {
      const featureFlags = Object.fromEntries(
        Array.from(client.repository().simpleFeatures(), ([key, value]) => [key, value === "true"]),
      );
      console.table(featureFlags);
    }
  }, [client.repository().readyness]);
  return (
    <PageWithNoPadding name="welcome-page" title="Welcome" backgroundClassName="bg-secondary">
      <div style={{ backgroundColor: `var(--hero-bg)` }}>
        <PageSection>
          <div className="position-relative">
            <div className="position-absolute w-100 mt-n6">
              <UserSurveyBanner />
            </div>
          </div>
          <Row className="py-10">
            <Col sm="8" className="pb-10">
              <h1 className="display-3 fw-light">Manage your bp products technology, tools and teams in one place.</h1>
            </Col>
            <Col sm="4" className="d-flex align-items-center justify-content-end gap-2">
              {!loading ? (
                <>
                  {myProducts.length ? <JoinProductButton /> : null}
                  <CreateProduct />
                </>
              ) : (
                <Loader size="sm" />
              )}
            </Col>
          </Row>
        </PageSection>
      </div>
      <PageSection>
        <Row>
          <Col xs="12">{!loading && <ProductCards myProducts={myProducts} />}</Col>
        </Row>
      </PageSection>
      <PageSection>
        <Row>
          <Col xs="12">
            <EventCards eventDetails={events ?? EVENT_INFO} />
            <YallaCard />
            <AskAxel />
            <div className="pt-11"></div>
            <PromoCards />
          </Col>
        </Row>
      </PageSection>
    </PageWithNoPadding>
  );
}
