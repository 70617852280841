import { Col, Row } from "reactstrap";
import { LinkButton } from "../../components/link-button";

export function YallaCard() {
  return (
    <Row className="mt-11 mx-0 rounded-6" style={{ backgroundColor: "var(--yalla-hero-bg)" }}>
      <Col xs="12" md="6" className="p-9 d-flex flex-column justify-content-center">
        <h3 className="mb-0 fw-light">We’re rolling out Yalla, the bp internal developer platform</h3>
        <p className="mt-5 fw-light">
          Yalla simplifies development, embraces GitOps, and drives automation. We're working closely with strategic and
          greenfield projects to make Yalla the future of software delivery.
        </p>
        <div className="mt-5 d-flex flex-wrap gap-3">
          <LinkButton
            name="join-yalla"
            href="https://bp.enterprise.slack.com/archives/C07HKG1ABT7"
            className="primary pe-4"
            level="primary"
            theme="standard"
            size="md"
            hideExternalLinkIcon
            rounded="pill"
            iconPosition="start"
          >
            <span className="ps-3">Join the Slack channel</span>
          </LinkButton>
          <LinkButton
            name="yalla-find-out-more"
            href="https://basproducts.atlassian.net/wiki/spaces/YallaPlatform/pages/5753475166/What+is+Yalla"
            className="bg-primary"
            level="tertiary"
            theme="standard"
            rounded="pill"
            hideExternalLinkIcon
          >
            Find out more
          </LinkButton>
        </div>
      </Col>

      <Col xs="12" md="6" className="d-flex justify-content-center align-items-center p-4">
        <img
          alt="illustration"
          src="/images/yalla-home.svg"
          className="img-fluid pt-6"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </Col>
    </Row>
  );
}
