import { JoinProductButton } from "./join-product-button";

export function FindProductsCard() {
  return (
    <div className="d-flex w-100  justify-content-center rounded-5">
      <div className="text-center p-5">
        <p className="fw-light">Find a product</p>
        <p className="fw-light small w-60 mx-auto">
          Search through all the products on Accelerate or connect an existing Service Now product.
        </p>
        <JoinProductButton />
      </div>
    </div>
  );
}
