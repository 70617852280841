import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { USER_HAS_SURVEY } from "../../api/users/gql";
import { SurveyBanner } from "../../components/survey-banner";

export function UserSurveyBanner() {
  const { data: userSurveys } = useQuery(USER_HAS_SURVEY);

  const userHasSurveys = userSurveys?.hasSurvey ? [...userSurveys.hasSurvey] : [];

  // Use useMemo to optimize sorting, slicing, and mapping
  const surveyBanners = useMemo(() => {
    return userHasSurveys
      .sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())
      .slice(-1) // Get the latest survey
      .map((survey) => <SurveyBanner key={survey.surveyId} {...survey} />);
  }, [userHasSurveys]);

  return <>{surveyBanners}</>;
}
