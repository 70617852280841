import { LinkButton } from "../../components/link-button";

export function JoinProductButton() {
  return (
    <LinkButton
      name="join-yalla"
      href="/all-products"
      level="tertiary"
      className="me-3 bg-primary"
      theme="standard"
      size="md"
      hideExternalLinkIcon
      rounded="pill"
      iconPosition="start"
    >
      <span className="ps-3">Join a product</span>
    </LinkButton>
  );
}
