import { Row, Col } from "reactstrap";
import { ProductCard } from "./product-card";
import { FindProductsCard } from "./find-products-card";
import { ProductType } from "../../providers/product-provider";

type ProductCardsProps = {
  myProducts: ProductType[];
};

export function ProductCards({ myProducts }: ProductCardsProps) {
  return (
    <Row className="mt-n8">
      <Col>
        <Row>
          {myProducts && myProducts?.length > 0 ? (
            myProducts.map((product) => <ProductCard product={product} key={product.id} />)
          ) : (
            <FindProductsCard />
          )}
        </Row>
      </Col>
    </Row>
  );
}
