import { Col, Row } from "reactstrap";
import { Link } from "../../components/Link";
import { Right16 } from "@bphxd/ds-core-react/lib/icons/index";

const cards = [
  {
    id: "1",
    title: "Tap into ten years of research",
    description: "Join your colleagues who are boosting their team performance with our industry-proven framework.",
    href: "/best-practices",
    imgSrc: "/images/dora-research-cycle.svg",
    linkName: "discover-the-framework",
    link: "Discover the framework",
  },
  {
    id: "2",
    title: "Unlock your team potential",
    description:
      "For our new assessment, covering 26 capabilities, we are creating documentation, tools, and workshops to help your team perform.",
    href: "/my-products",
    imgSrc: "/images/png/my-products.png",
    linkName: "explore-the-resources",
    link: "Explore the resources",
  },
  {
    id: "3",
    title: "Equip your team for success",
    description: "Our endorsed kit of tools support your teams across all areas of software delivery.",
    href: "/technology/tools",
    bgColor: "#E1F5FD",
    imgSrc: "/images/png/tools.png",
    linkName: "discover-the-tools",
    link: "Discover the tools",
  },
];

export function PromoCards() {
  return (
    <Row className="border-top pt-11">
      {cards.map(({ id, title, description, linkName, imgSrc, href, link, bgColor }, index) => (
        <Col xs="12" md="4" className={"d-flex justify-content-center align-items-center"} key={id}>
          <div className="d-flex flex-column">
            <img
              alt={title}
              src={imgSrc}
              className={`img-fluid ${index === 1 ? "mt-5" : "mt-0"} rounded-5`}
              style={{ backgroundColor: bgColor ?? "" }}
            />
            <h4 className="fw-light mt-6 pb-0 mb-4">{title}</h4>
            <p className="fw-light mb-0">{description}</p>
            <Link name={linkName} href={href} className="fw-light mt-5">
              {link}
              <Right16 className="x5-ms-1" />
            </Link>
          </div>
        </Col>
      ))}
    </Row>
  );
}
