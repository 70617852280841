import { Col, Row } from "reactstrap";
import { AskAxelButton } from "../../components/button";

const axelCards = [
  {
    id: "1",
    description: "Please explain what A/B Testing is, and how can I adopt it using ADO Pipelines",
  },
  {
    id: "2",
    description: "What is the adoption status of Appian as a technology within BP?",
  },
  {
    id: "3",
    description: "How do I reduce the number of bugs in my application?",
  },
  {
    id: "4",
    description: "How do I get access to Grafana?",
  },
];

export function AskAxel() {
  return (
    <>
      <div className="mt-11">
        <Row className="align-items-center">
          <Col xs="12" md="6" className="mb-3 mb-md-0">
            <div className="d-flex flex-column mx-auto">
              <h3 className="mb-0 fw-light mb-2">
                Search for digital technology information with <b>Axel Search!</b>
              </h3>
              <ul>
                <li className="mt-3 mb-0 fw-light">Find solutions to technical issues within bp quickly.</li>
                <li className="mt-3 fw-light">Learn from the code other product teams have written.</li>
                <li className="mt-3 fw-light">Chat with an AI to draft code and documentation.</li>
              </ul>
              <div className="mt-6">
                <AskAxelButton chatPrompt="what is accelerate?" chatName="Ask axel a question" name="ask-accelerate">
                  Ask a question
                </AskAxelButton>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6" className="d-flex justify-content-center">
            <img
              alt="illustration"
              src="/images/png/ask-axel-4-3.png"
              className="img-fluid pt-6"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </div>
      <div className="mt-10">
        <h5 className="fw-light">Try some suggested prompts</h5>
        <Row className="mt-4">
          {axelCards.map(({ id, description }) => (
            <Col key={id} xs="12" sm="6" lg="3" className="mb-5">
              <div className="d-flex flex-column p-6 rounded-5 bg-primary h-100">
                <p className="fw-light mb-0">{description}</p>
                <div className="mt-3 d-flex justify-content-end">
                  <AskAxelButton
                    chatPrompt={description}
                    chatName={description}
                    iconOnly
                    name={`ask-axel`}
                    style={{ border: "1px solid #ccc" }}
                    className="d-flex justify-content-center"
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
