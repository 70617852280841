import { ProductFavorite } from "../products/product-favorite";
import { ProductFavoriteButton } from "../products/product-favorite-button";
import { ProductAdminTooltip } from "../profile/products/product-admin-tool-tip";
import { useUserIsAdmin } from "../../hooks/use-user-is-admin";
import { Link } from "../../components/Link";
import { ProductType } from "../../providers/product-provider";

type ProductCardProps = {
  product: ProductType;
};
export function ProductCard({ product }: ProductCardProps) {
  const userIsAdmin = useUserIsAdmin(product);

  return product ? (
    <div className="col-xs-12 col-lg-6 col-xl-6 col-xxl-4 mb-5">
      <div
        className="bg-primary d-flex flex-column shadow-sm position-relative rounded-5"
        style={{ minHeight: "16rem" }}
      >
        <div className="d-flex flex-column px-7 py-6 flex-grow-1">
          <Link
            dataTestid={product.name}
            href={`/profile/product/${product?.id}/?tab=1-overview&product_name=${product.name}&user_is_admin=${userIsAdmin}`}
            className="fw-light lead mb-2"
          >
            {product.name}
          </Link>
          <p className="small fw-light mb-0">{product.description}</p>
        </div>
        <div className="d-flex align-items-center px-7 pb-5">
          <ProductFavorite productId={product.id} />
          <ProductFavoriteButton productId={product.id} />
          {userIsAdmin && <ProductAdminTooltip />}
        </div>
      </div>
    </div>
  ) : null;
}
